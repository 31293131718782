<template>
	<view class="common-tabbar">
		<view class="placeholder">
			<view class="box"></view>
			<bottom-box></bottom-box>
		</view>
		<view class="content">
			<view class="box">
				<view
					class="item"
					:key="index"
					v-for="(item, index) in tabbar"
					:class="{ active: item.path == path }"
					@tap="item.path != path && $navigateTo(item.path)"
				>
					<image :src="item.path == path ? item.actIcon : item.icon" mode="aspectFit" />
					<view class="name">{{ item.text }}</view>
				</view>
			</view>
			<bottom-box></bottom-box>
		</view>
	</view>
</template>

<script lang="ts" setup>
import { onShow } from '@dcloudio/uni-app';
import systemInfo from '@/stores/systemInfo';
import { reactive, onMounted, getCurrentInstance } from 'vue';

const tabbar = reactive([
	{
		text: '首页',
		path: '/pages/home',
		icon: '/static/images/home.png',
		actIcon: '/static/images/home-act.png',
	},
	{
		text: '追球',
		path: '/pages/playlet/follow',
		icon: '/static/images/follow.png',
		actIcon: '/static/images/follow-act.png',
	},
	{
		text: '我的',
		path: '/pages/mycenter/index',
		icon: '/static/images/mycenter.png',
		actIcon: '/static/images/mycenter-act.png',
	},
]);

const instance = getCurrentInstance();

defineProps<{
	path: string;
}>();

onShow(() => uni.hideTabBar({ fail: () => {} }));

onMounted(() => {
	uni
		.createSelectorQuery()
		.in(instance)
		.select('.common-tabbar .placeholder')
		.boundingClientRect(({ height }: any) => (systemInfo().th = height))
		.exec();
});
</script>

<style lang="scss" scoped>
.common-tabbar {
	.content {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 99;
		width: 100%;
		background-color: #fff;
		border-top: $border;
	}
	.content,
	.placeholder {
		.box {
			height: 98rpx;
			display: flex;
			.item {
				flex: 1;
				height: 100%;
				@include center;
				flex-direction: column;
				&.active .name {
					color: $main-color;
				}
				image {
					width: 40rpx;
					height: 40rpx;
				}
				.name {
					font-size: 22rpx;
					color: #666666;
					line-height: 32rpx;
				}
			}
		}
	}
}
</style>
