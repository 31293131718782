import App from './App.vue';
import * as Pinia from 'pinia';
import mixin from '@utils/mixin';
import mounts from '@utils/mounts';
import { createSSRApp } from 'vue';
import tabbar from '@common/tabbar.vue';
import statusBox from '@common/status-box.vue';
import bottomBox from '@common/bottom-box.vue';

export const createApp = () => {
	const app = createSSRApp(App);
	app
		.mixin(mixin)
		.use(mounts)
		.use(Pinia.createPinia())
		.component('tabbar', tabbar)
		.component('statusBox', statusBox)
		.component('bottomBox', bottomBox);

	return { app, Pinia };
};
