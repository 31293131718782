<template>
	<view class="common-status-box" :style="{ height: sysInfo.sh + 'px' }"></view>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';
import systemInfo from '@/stores/systemInfo';

const sysInfo = systemInfo();

onMounted(() => {
	const { top } = uni.getWindowInfo().safeAreaInsets;
	top && (sysInfo.sh = top);
});
</script>
